<div class="page-body">
  <div>
    <header
    [breadcrumbPath]="breadcrumb" isHomeIconToPortal="true"
    ></header>
    <div class="page-content is-fullpage">
      <div *ngIf="!this.isLoaded && !this.isLoadFailed">
        Loading
      </div>
      <div *ngIf="this.isLoadFailed">
        You do not have the required roles to access this page.
      </div>
      <div *ngIf="this.isLoaded" class="dashboard-cards-container">

        <div class="card dashboard-card">
          <div class="image-holder">
            <img src="https://mathproficiencytest.ca/img/test-window-icon.png">
          </div>
          <div class="table-holder">
            <h2> <tra slug="lbl_test_windows"></tra> </h2>
            <div class="table-holder-container">
              <table class="table is-bordered is-hoverable" style="min-width: 34em;">
                <tr *ngFor="let tw of testWindowSummaries">
                  <td>
                    <div class="lead"> {{renderTwTitle(tw)}} </div>
                    <div> {{renderTwDateStart(tw)}} </div>
                    <div> {{renderTwDateEnd(tw)}} </div>
                  </td>
                  <td>
                    <div><a (click)="disabled()"> {{renderTwNumSessionsCr(tw)}} <tra slug="txt_test_sessions_created"></tra>  </a> </div>
                    <div><a (click)="disabled()"> {{renderOldUnclosed(tw)}} <tra slug="old and unclosed"></tra>  </a> </div>
                    <!-- <div><a (click)="disabled()"> {{renderTwNumSessionsAdm(tw)}} <tra slug="txt_test_sessions_adminstrd"></tra>  </a> </div> -->
                    <!-- <div><a (click)="disabled()"> {{renderTwNumResPend(tw)}} <tra slug="txt_results_pending_reles"></tra>  </a> </div> -->
                  </td>
                  <td style="width:6em;">
                    <a [routerLink]="getTestWindowRoute(tw.id)" class="button">
                      <tra slug="btn_manage"></tra>
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <div class="table-footer-spread">
              <div></div>
              <button (click)="createTestWindow()"  class="button is-success">
                <tra slug="btn_create_test_window"></tra>
              </button>
            </div>
            
            <hr>

            <div class="flex-header-container">
              <h2> Sessions for Current and Upcoming Windows </h2>
              <a [href]="getSessionListDlLink()" class="button is-info">Download List of Sessions</a>
            </div>
            
          </div>
        </div>

        <div class="card dashboard-card">
          <div class="image-holder">
            <img src="https://mathproficiencytest.ca/img/account-icon.png">
          </div>
          <div class="table-holder">
            <div class="flex-header-container">
              <h2> <tra slug="title_accounts_roles"></tra> </h2>
              <div class="flex-header-ctrl">
                <label class="checkbox">
                  <input type="checkbox" [formControl]="showLast30days">
                  <span>Show last 30 days</span>
                </label>
                <button class="button is-info" (click)="refreshData()">refresh</button>
              </div>
            </div>
            
            <div class="table-holder-container">
              <table class="table is-bordered is-hoverable is-number-table" style="min-width: 34em;">
                <tr>
                  <td></td>
                  <th><tra slug="lbl_table_registered"></tra></th>
                  <th *ngIf="showLast30days.value"><tra slug="lbl_table_last30"></tra></th>
                  <th><tra slug="lbl_table_pending_invitation"></tra></th>
                </tr>
                <tr>
                  <th class="is-left-aligned"><tra slug="lbl_table_test_applicants"></tra></th>
                  <td>{{renderAccountTally('num_test_applicants', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_test_applicants', 'l30')}}</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="is-left-aligned"><tra slug="lbl_table_session_bookings"></tra></td>
                  <td>{{renderAccountTally('num_session_bookings', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_session_bookings', 'l30')}}</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="is-left-aligned"><tra slug="lbl_table_session_waitlisters"></tra></td>
                  <td>{{renderAccountTally('num_session_waitlisters', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_session_waitlisters', 'l30')}}</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="is-left-aligned"><tra slug="lbl_table_accom_req"></tra></td>
                  <td>{{renderAccountTally('num_accomm_pending', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_accomm_pending', 'l30')}}</td>
                  <td></td>
                </tr>
                <tr>
                  <th class="is-left-aligned"><tra slug="lbl_table_test_admin"></tra></th>
                  <td>{{renderAccountTally('num_institutions', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_institutions', 'l30')}}</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="is-left-aligned"><tra slug="lbl_table_inst_mngr"></tra></td>
                  <td>{{renderAccountTally('num_inst_mngrs', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_inst_mngrs', 'l30')}}</td>
                  <td>{{renderAccountTally('num_inst_mngrs', 'p')}}</td>
                </tr>
                <tr>
                  <td class="is-left-aligned"><tra slug="lbl_table_accomm_coord"></tra></td>
                  <td>{{renderAccountTally('num_accomm_coords', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_accomm_coords', 'l30')}}</td>
                  <td>{{renderAccountTally('num_accomm_coords', 'p')}}</td>
                </tr>
                <tr>
                  <td class="is-left-aligned"><tra slug="lbl_table_invig"></tra></td>
                  <td>{{renderAccountTally('num_invigs', 'r')}}</td>
                  <td *ngIf="showLast30days.value">{{renderAccountTally('num_invigs', 'l30')}}</td>
                  <td>{{renderAccountTally('num_invigs', 'p')}}</td>
                </tr>
                <tr>
                  <th class="is-left-aligned"><tra slug="Certification Body"></tra></th>
                  <td>{{renderAccountTally('num_cert_bodys', 'r')}}</td>
                  <td class="is-minor-val"  *ngIf="showLast30days.value">{{renderAccountTally('num_cert_bodys', 'l30')}}</td>
                  <td class="is-minor-val">{{renderAccountTally('num_cert_bodys', 'p')}}</td>
                </tr>
                <tr>
                  <th class="is-left-aligned"><tra slug="Test Controller"></tra></th>
                  <td>{{renderAccountTally('num_test_ctrls', 'r')}}</td>
                  <td class="is-minor-val"  *ngIf="showLast30days.value">{{renderAccountTally('num_test_ctrls', 'l30')}}</td>
                  <td class="is-minor-val">{{renderAccountTally('num_test_ctrls', 'p')}}</td>
                </tr>
              </table>
            </div>
            <div  class="table-footer-spread">
              <div class="hide-on-sml">&nbsp;</div>
              <a [routerLink]="getAccountsRoute()" class="button is-success">
                <tra slug="btn_manage_accounts"></tra>
              </a>
              <a *ngIf="showAppealButton()" [routerLink]="getAppealsRoute()" class="button is-success">
                <tra slug="btn_appeals"></tra>
              </a>
            </div>
          </div>
        </div>

        <div class="card dashboard-card" *ngIf="isTestCtrlReportingExplorerEnabled()">
          <div class="image-holder">
            <img style="filter: saturate(0);" src="https://mathproficiencytest.ca/img/account-icon.png">
          </div>
          <div class="table-holder">
            
            <a class="button is-info" routerLink="/en/test-ctrl/reporting">View Reporting Explorer</a>
            <div>
              <div class="flex-header-container">
                <!-- <h2> 
                  Test Centre Summary
                </h2>
                <div class="flex-header-ctrl">
                  <button *ngIf="isInstSummaryLoaded" class="button is-info" (click)="loadInstSummary()">refresh</button>
                </div> -->
              </div>
              <!-- <div *ngIf="!isInstSummaryLoading && !isInstSummaryLoaded">
                <button class="button is-info" (click)="loadInstSummary()">Load Test Centre Summary</button>
              </div> -->
              <div *ngIf="isInstSummaryLoading">
                Loading...
              </div>
              <div *ngIf="isInstSummaryLoaded && !isInstSummaryLoading && instSummaryList.length == 0" class="notification is-warning">
                There is currently no test centre info
              </div>
              <div *ngIf="isInstSummaryLoaded && instSummaryList.length > 0" class="table-holder-container">
                <table class="table is-bordered is-hoverable " style="min-width: 34em;">
                  <tr>
                    <th>Test Centre</th>
                    <th>City</th>
                    <th>Available Sessions</th>
                    <th>Available Capacity</th>
                    <th>Used Capacity</th>
                    <th>Pending Accomm. Req.</th>
                  </tr>
                  <tr *ngFor="let inst of instSummaryList">
                    <td>{{inst.name}}</td>
                    <td>{{inst.city}}</td>
                    <td>{{inst.sessions}}</td>
                    <td>{{inst.capacity}}</td>
                    <td>{{inst.booked}}</td>
                    <td>{{inst.pending_accomm}}</td>
                  </tr>
                </table>
              </div>
            </div>

          </div>
        </div>

        <!-- <div style="margin-top:2em;">
          <a routerLink="/en/test-ctrl/seb-gen">seb config</a>
        </div> -->
        <div class="card dashboard-card">
          <div class="image-holder">
            <i class="icon-checked fa fa-check-circle fa-5x" aria-hidden="true"></i>
          </div>
          <div class="table-holder">
            <div class="flex-header-container">
              <h2> <tra slug="title_tech_ready"></tra> </h2>
            </div>
            <view-tc-technical-readiness-verification></view-tc-technical-readiness-verification>
          </div>
        </div>
        <div class="card dashboard-card"> <!-- Manage List  -->
          <div>
            <h2>System-Level Messages</h2>
            <ul>
              <li><a routerLink="/en/test-ctrl/help-page">Help Page</a></li>
            </ul>
          </div>
        </div>

      </div>

    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>
