import * as moment from 'moment-timezone';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { SEBService } from '../../ui-testtaker/seb.service';
import { TimezoneService } from '../../core/timezone.service';
import { IUserInfo, AuthService } from '../../api/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import { AccountType } from '../../constants/account-types';
import { Title } from '@angular/platform-browser';
import { WhitelabelService } from '../../domain/whitelabel.service';
import {ChatService} from '../../chat/chat.service';
import { interval, Subscription } from 'rxjs';
import { LoginGuardService } from '../../api/login-guard.service';
import { NotificationsService } from 'src/app/ui-item-maker/notifications.service';

export const getSiteSectionColor = (accountType: AccountType | null) => {
  switch (accountType) {
    case AccountType.TEST_ADMIN : return 'rgba(255, 214, 0, 0.37)';
    case AccountType.TEST_TAKER : return 'rgba(99, 237, 35, 0.37)';
    case AccountType.TEST_CTRL : return 'rgba(236, 64, 64, 0.37)';
    case AccountType.CERT_BODY : return 'rgba(0, 0, 0, 0.37)';
    default: return 'rgba(64, 143, 236, 0.37)';
  }
};

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isHomeIconToPortal: boolean = false;
  @Input() breadcrumbPath: {caption: string, route: string, params?}[];
  @Input() siteSection: boolean;
  @Input() accountInfo: string;
  @Input() hasSidebar: boolean;
  @Input() hideSupportBtn: boolean;
  @Input() isLoginPage: boolean;
  @Input() isLoginHidden: boolean;
  @Input() hasPrint: boolean;
  @Input() icon: string;
  @Input() accountType: AccountType;
  @Input() newMessage: boolean;
  @Input() messagePoolId: string;
  @Input() noLangSwitcher: boolean
  @Input() isOpaque: boolean
  @Input() techSupportDataKey: string
  @Input() showHelpPage: boolean

  userInfo: IUserInfo;
  isMenuRevealed: boolean;
  schoolType: string;
  readonly AccountTypes = AccountType


  langs = [
    {code: 'en', caption: 'English'},
    {code: 'fr', caption: 'Français'},
  ];

  public timeZone: string;
  public usingSEB = false;
  subscription = new Subscription();
  notificationSub = new Subscription();

  constructor(
    public langService: LangService,
    public timezone: TimezoneService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private seb: SEBService,
    private whitelabel: WhitelabelService,
    private titleService: Title,
    private loginGuard: LoginGuardService,
    private lang: LangService,
    public notifications: NotificationsService
    // private headerChatService: ChatService
  ) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if(this.notificationSub) {
      this.notificationSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.auth
      .user()
      .subscribe(t => {
        console.log('user sub', t)
        this.initTimezone();
        return this.userInfo = t
      })
    );
    this.subscription.add(
      this.seb.getSub().subscribe(isSEB => {
        console.log(`header isSEB: ${isSEB}`)
        this.usingSEB = isSEB
      })
    )

    this.updateTitle();
    this.initTimezone();
    // this.updateTitle();
    // this.timeZone = moment.tz.guess();
    this.schoolType = this.route.snapshot.data['schoolType'] === 'BC_GRAD' ? 'bc-grad' : 'bc-fsa';
    this.notifications.refreshData();
    const source = interval(60000);
    this.notificationSub = source.subscribe( val => {this.notifications.refreshData();}) //refresh unread data every minute
  }

  async initTimezone(){
    this.timeZone = await this.timezone.getTimezone();
  }

  supportReqActivate(){
    let techSupportInfo;
    if (this.techSupportDataKey){
      techSupportInfo = this.whitelabel.getSiteData(this.techSupportDataKey)
    }
    this.loginGuard.supportReqActivate(techSupportInfo)
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.breadcrumbPath){
      this.updateTitle();
    }
  }

  goBackHome(){
    const msg = this.langService.tra('txt_alert_login_home');
    this.router.navigateByUrl(`/${this.langService.c()}/login-portal`)
  }

  updateTitle() {
    if (this.breadcrumbPath && this.breadcrumbPath.length > 0) {
      const crumb = this.breadcrumbPath[this.breadcrumbPath.length - 1];
      // CAEC Branding update`
      if (this.whitelabel.isTestCenter) {
        switch(crumb.route) {
          case "/fr/candidate-public-practice":
            // en case not included since the label is "Sample Tests" but the header/crumb is "Practice Tests". Falls to default case.
            this.titleService.setTitle( this.langService.tra('lbl_practice_tests_caec') + ' | ' + this.langService.tra(this.getBranding()));
            break;
          case "/fr/general/no-email-login":
            this.titleService.setTitle( this.langService.tra('caec_no_email_login_title') + ' | ' + this.langService.tra(this.getBranding()));
            break;
          default:
            this.titleService.setTitle( this.langService.tra(crumb.caption) + ' | ' + this.langService.tra(this.getBranding()) );
        }
      } else {
        this.titleService.setTitle( this.langService.tra(crumb.caption) + ' | ' + this.langService.tra(this.getBranding()) );
      }
    }
  }

  isUsingSebSoftware(){
    return navigator.userAgent.match(/SEB/i) ? true: false
  }

  getWhitelabelUrl(slug:string){
    return this.whitelabel.getSiteText(slug);
  }

  isBreadcrumbMenuEnabled() {
    return this.whitelabel.getSiteFlag('BREADCRUMB_MENU');
  }

  getHomeScreenRoute() {
    return this.whitelabel.getHomepageRoute();
  }

  getCrumbRoute(crumb) {
    if (crumb.caption === this.langService.tra('title_create_sessions')) return this.router.url;
    return crumb.route;
  }

  getBranding() {
    return this.whitelabel.currentBrandName;
  }

  getStripColor() {
    return getSiteSectionColor( this.getAccountType() );
  }

  toggleMenu() {
    this.isMenuRevealed = !this.isMenuRevealed;
  }

  getDashboardRoute(){
    if(this.isHomeIconToPortal){
      return `/${this.langService.c()}/login-portal`;
    }
    return this.auth.getDashboardRoute(this.langService.c())
  }

  hasAccountType(accountType: AccountType){
    if(this.userInfo){
      return this.userInfo.accountType === accountType || this.userInfo.accountTypes.find(t => t.account_type === accountType)
    }
  }

  getAccountType(useDefault: boolean= false) {
    if (!this.accountType) {
      if (this.userInfo) {
        return this.userInfo.accountType;
      }
    } else {
      return this.accountType;
    }
    if (useDefault) {
      return AccountType.TEST_TAKER;
    }
    return null;
  }

  getUserDisplayName() {
    if(!this.userInfo) return null;
    return this.userInfo.firstName + ' ' + this.userInfo.lastName;
  }

  getLang() {
    return this.langService.getCurrentLanguage();
  }

  isLangActive(langCode: string) {
    return (this.getLang() === langCode);
  }

  setLang(langCode: string) {
    this.langService.loadInLang(langCode);
  }
  isEnglish(){
    return this.isLangActive('en');
  }

  getLoginRoute() {
    return `/${this.langService.c()}/${this.getAccountType(true)}/login/true`;
  }

  logout() {
    const loginRoute = this.getLoginRoute();
    this.auth
      .logout()
      .then( r => {
        this.router.navigate([loginRoute]);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      });
  }

  onClickNewMessage() {
    console.log('click');
  }

  getNotificationsText() {
    let text = "Notifications";
    // let text = "";

    const numUnread = this.notifications.getNumUnread();

    if(numUnread) {
      text += ` (${numUnread})`
    }

    return text;
  }

  isLoggedIn() {
    return this.auth.checkLoggedIn()
  }

  navigateToJurisdictionSettings(){
    this.router.navigate([`/${this.lang.c()}/test-cert/candidate-records/settings`], { queryParamsHandling: 'preserve' });
  }
  navigateToHelpPage(){
    this.router.navigate([`/${this.lang.c()}/caec-help`], { queryParamsHandling: 'preserve' });
  }

  isTestCenter(){
    return this.whitelabel.isTestCenter();
  }
}
