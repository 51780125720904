import * as moment from 'moment-timezone';

import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { LoginGuardService } from '../api/login-guard.service';
import { RoutesService } from '../api/routes.service';
import { Router } from '@angular/router';
import { AccountType } from '../constants/account-types';
import { LangService } from '../core/lang.service';
import { TimezoneService } from '../core/timezone.service';
import { EDeliveryOption } from '../api/models/db/test-sessions.schema';
import { ITestSessionAvailTT, ITestSessionAvailTTExt } from '../api/models/db/test-session-avail-tt.schema';
import { SafeResourceUrl } from '@angular/platform-browser';
import { WhitelabelService } from '../domain/whitelabel.service';
import { IJurisdictionInfo } from '../ui-jurisdiction/types/api-response';

export interface IMyApplAcct {
  uid: number,
  name: string,
  capId: string,
}
export enum InvigLang {
  FRENCH_INVIG = 'French Invigilation',
  ENGLISH_INVIG = 'English Invigilation',
  BILINGUAL_INVIG = 'Bilingual Invigilation (English and French) '
}

export interface ISessionTT {
  m: moment.Moment;
  id:number,
  sessionNum:number,
  instGroupId:number,
  testWindowTitle: string,
  dateDisplay?: string,
  timeFrom: string,
  timeTo: string,
  institutionName?: string,
  testLocation?: string,
  hasAccessCode: boolean, 
  status: string,
  timeZone: string,
  isByod: boolean,
  isFull: boolean,
  isBookable?: boolean,
  isExpired?: boolean,
  isFilteredOut?:boolean,
  test_attempt_id?:number,
  delivery_format?:string,
  videostream_link?:string,
  videostream_password?:string,
  is_seb_disabled?:boolean,
  invigLang?:string,
  ts_invigLang?:string,
  //
  videostreamSafeUrl?:SafeResourceUrl, // tacked on in the client
  twtdar_long_name?:string
}

@Injectable({
  providedIn: 'root'
})
export class MyApplAccountService {

  constructor(
    private auth: AuthService,
    private loginGuard: LoginGuardService,
    private routes: RoutesService,
    private router: Router,
    // private location: Location,
    private lang: LangService,
    private whiteLabel: WhitelabelService,
    private timezone: TimezoneService,
  ) { 
    this.init();
  }

  public isMakingApiReq:boolean = false;

  private info:BehaviorSubject<IMyApplAcct> = new BehaviorSubject(null);
  private myJurisInfo:BehaviorSubject<IJurisdictionInfo> = new BehaviorSubject(null);
  public myJurisInfo$ = this.myJurisInfo.asObservable();

  private init(){
    this.auth.user().subscribe(userInfo => {
      if(userInfo){
        this.info.next({
          uid: userInfo.uid,
          name: userInfo.firstName + ' ' + userInfo.lastName,
          capId: '224466',
        });
      }
      else{
        this.info.next(null);
      }
    })
  }

  public sub(){
    return this.info;
  }

  public getDisplayName(){
    return this.info.getValue().name
  }

  public async sendStripeSessionForAppeal() {
    const lang_code = this.lang.c();
    const currentDomain = window.location.href;
    let domainSplit = currentDomain.split('/');
    const attemptId = parseInt(domainSplit[domainSplit.length - 1]);
    const type = 'APPEAL';
    const body = {type, attemptId, lang_code};
    const stripeCheckout = await this.auth.apiCreate(
      this.routes.TEST_TAKER_TEST_SESSIONS_STRIPE_CHECKOUT,
      body
    );
    location.assign(stripeCheckout.url);
  }

  public async sendStripeSessionForCreditPurchase(test_session_id: number, access_code: string, lang: string, isAccommReq: boolean ,isRequestFromHome: boolean = false, isRequestFromInvitation?: boolean, secretKey?: string) {
    const lang_code = lang;
    const isAccomm = isAccommReq ? 1 : 0;
    const type = 'CREDIT_PURCHASE';
    const body = {test_session_id, type, lang_code, isAccomm, access_code, sessionSecret: secretKey};
    // Could add the access code here too for the invitation
    try {
      const stripeCheckout = await this.auth.apiCreate(
        this.routes.TEST_TAKER_TEST_SESSIONS_STRIPE_CHECKOUT,
        body,
        {query: {isRequestFromHome, isRequestFromInvitation}}
      );
      location.assign(stripeCheckout.url);
    } catch (err) {
      console.log("🚀 ~ file: my-appl-account.service.ts:129 ~ MyApplAccountService ~ sendStripeSessionForCreditPurchase ~ err:", err)
      if (err.message === 'TEST_CENTRE_STRIPE_CONFIG')
        throw 'TEST_CENTRE_STRIPE_CONFIG'
    }
  }
  
  bookSession(test_session_id:number, access_code: string, paymentsEnabled?: boolean, group_id?: number, isConfirmation: boolean = false, isTransferToBookableSession: boolean = false){
    if (!this.isMakingApiReq) {
      this.isMakingApiReq = true;
      return this.auth.apiCreate(
        this.routes.TEST_TAKER_TEST_SESSIONS_BOOKING,
        { test_session_id, access_code, lang: this.lang.c(), group_id, isConfirmation: isConfirmation ? 1 : 0, isTransferToBookableSession}
      )
      .then((res) => {
        this.isMakingApiReq = false;
        return res;
      })
      .catch(e => {
        this.isMakingApiReq = false;
        const {caption, confirmAction} = this.getBookingErrorResult(e);
        this.loginGuard.confirmationReqActivate({caption, confirm: confirmAction });
        throw e;
      }) 
    }
    return Promise.resolve();
  }
  
  getBookingErrorResult(error: any): {caption: string, confirmAction: ()=>void} {
    let caption = '';
    let confirmAction = () => {};
    switch (error.message) {
      case 'INVALID_ACCESS_CODE':
        caption = 'msg_invalid_code';
        break;
      case 'TEST_SESSION_FULL':
      case 'TRANSFER_REQ_EXCEEDS_CAP':
        caption = this.lang.tra('msg_session_full');
        break;
      case 'TEST_SESSION_BUFFER_CLOSED':
        caption = 'msg_session_closed';
        break;
      case 'EXISTING_BOOKING':
      case 'TEST_COMPLETED':
      case 'YEARLY_BOOKING_LIMIT_EXCEEDED':
        caption = 'caec_msg_reg_error_completed';
        break;
      case 'MPT_STATUS_NOT_ELIGIBLE':
        caption = 'notif_restricted_booking_simple';
        break;
      case 'ERR_NO_CRED_AVAIL':
        caption = 'caec_err_book_no_credits';
        break;
      case 'INVALID_TRANSFER_REQUEST':
        caption = 'caec_err_book_inval_transfer_req';
        break;
      case 'AGE_LIMIT':
        caption = 'abed_caec_book_age_err';
        break;
      case 'ALREADY_PENDING':
        caption = 'caec_msg_reg_error_pending';
        break;
      case 'MAXIMUM_WAITLIST_REACHED':
        caption = 'msg_reg_error_max_wait';
        break;
      default:
        caption = 'abed_caec_general_book_err';
        break;
    }
    return {caption, confirmAction};
  }

  waitlistSession(test_session_id:number, access_code: string, isConfirmation: boolean = false){
    if (!this.isMakingApiReq) {
      this.isMakingApiReq = true;
      return this.auth.apiCreate(
        this.routes.TEST_TAKER_TEST_SESSIONS_WAITLIST,
        { test_session_id, access_code, lang: this.lang.c(), isConfirmation: isConfirmation ? 1 : 0}
      )
      .then( (res) => {
        this.isMakingApiReq = false;
        return res;
        // we already redirect to the dashboard in the calling function
        // this.gotoDashboard()
      })
      .catch( e => {
        this.isMakingApiReq = false;
        const isWaitlist = true;
        const {caption, confirmAction} = this.getBookingErrorResult(e);
        this.loginGuard.confirmationReqActivate({caption, confirm: confirmAction });
        throw e;
      })
    }
    return Promise.resolve();
  }

  gotoDashboard = () => {
    this.router.navigate([`/${this.lang.c()}/${AccountType.TEST_TAKER}/dashboard`]);
  }

  checkIfCreditsEnabled = () => {
    return this.auth.apiFind(this.routes.CREDITS_CREDIT_SYSTEM);
  }

  // Check user credit depending on the institution there are applying to
  getUserCreditInfo = (group_id?: number) => {
    return this.auth.apiFind(this.routes.TEST_TAKER_CREDIT_DETAILS, {query: {group_id: group_id}});
  }

  // Get credit info regardless of test-centers
  getUserCentersCreditInfo () {
    return this.auth.apiFind(this.routes.TEST_TAKER_CREDIT_DETAILS);
  }


  private paymentSystemStatus: {loaded: boolean, value: boolean} = {
    loaded: false,
    value: false
  };

  async getGlobalPaymentSystemStatus() {
    if (this.paymentSystemStatus.loaded) return this.paymentSystemStatus.value;

    this.paymentSystemStatus.value = await this.auth.apiFind(this.routes.CREDITS_PAYMENT_SYSTEM);
    this.paymentSystemStatus.loaded = true;
    return this.paymentSystemStatus.value;
  }

  // TODO: Check jurisInfo first, if not, querythe aPI for juris settings
  // TODO: define a prop for sys_config payments and credit to be checked
  checkIfPaymentsEnabled = async () => {
    // const sys_cons = await this.auth.apiFind(this.routes.CREDITS_PAYMENT_SYSTEM);
    const sys_cons = await this.getGlobalPaymentSystemStatus();
    let juris_settings = this.myJurisInfo.getValue();

    if (!juris_settings) { 
      juris_settings = await this.loadJurisdictionInfo();
    }
    
    if (juris_settings.usesCreditSystem === 0) {
      return false;
    } else return sys_cons
  }

  checkIfSEBEnforcedGlobally = async () => {
    let value = null;
    try {
      [value] = await this.auth.apiFind(this.routes.SYS_SEB_ENFORCEMENT);
    } catch (error) {
      console.log('error get sys const', error)
    }
    return value;
  }

  sanitizeSessionInfo(session:ITestSessionAvailTT, institutionName:string = '') : ISessionTT {
    const timezone = <string>this.timezone.getTimezone();
    const m = moment.tz(session.date_time_start, timezone);
    // '3:00pm, Sept. 16, 2020 (Wednesday)'
    const FMT_DATE_DAY_DISPLAY = this.lang.tra('datefmt_day_month_year_dow');
    const FMT_DATE_TIME_DISPLAY = this.lang.tra('datefmt_time');
    let testWindowTitle;
    try {
      const titles = JSON.parse(session.test_window_title);
      testWindowTitle = titles[this.lang.c()];
    }
    catch(e){}

    let isBookable = true;
    if (session.pending !== undefined) {
      isBookable = session.pending === 0 ? true : false;
    }
    return {
        m,
        id:session.id,
        delivery_format: session.delivery_format,
        videostream_link: session.videostream_link,
        videostream_password: session.videostream_password,
        sessionNum:0,
        instGroupId:session.instit_group_id,
        dateDisplay: m.format(FMT_DATE_DAY_DISPLAY),
        timeFrom: m.format(FMT_DATE_TIME_DISPLAY),
        timeTo: m.format(FMT_DATE_TIME_DISPLAY),
        timeZone: timezone,
        institutionName,
        testWindowTitle,
        status: session.status,
        is_seb_disabled: session.is_seb_disabled == 1 ? true: false,
        testLocation: [
          `${this.lang.tra('cts_location_room_lbl')} ${this.renderRoom(session)}, ${session.campus_building}`,
          `${session.address}`,
          `${session.city} ${session.province} ${session.postal_code}`,
          session.phone_number ? `${session.phone_number}` : '',
        ].filter(line => line.trim() !== '').join('\n'),
        hasAccessCode: session.is_access_code_enabled === 1, 
        isByod: session.delivery_format === EDeliveryOption.BYOD,
        isFull: session.booked ? session.booked >= session.capacity : false,
        isBookable: isBookable,
        twtdar_long_name: session.twtdar_long_name,
        invigLang: this.getInvigLangSlug(session.invigLang) || this.getInvigLangSlug(session.ts_invigLang),
    }
  }

  private getInvigLangSlug(lang:string){
    if(lang == InvigLang.ENGLISH_INVIG) return this.lang.tra('lbl_en');
    if(lang == InvigLang.FRENCH_INVIG) return this.lang.tra('lbl_fr');
    if(lang == InvigLang.BILINGUAL_INVIG) return this.lang.tra('lbl_bil')

  }
  
  private renderRoom(session: ITestSessionAvailTT) {
    let room: string;
    if (session.delivery_format === 'remote') {
      room = this.lang.tra('tra_remote');
    } else {
      room = session.room;
    }
    return room;
  }
  
  private jurisInfoLoadingPromise: Promise<IJurisdictionInfo> | null = null;

  async loadJurisdictionInfo() {
    if (this.jurisInfoLoadingPromise) return this.jurisInfoLoadingPromise;

    this.jurisInfoLoadingPromise = (async () => {
      try {
        const juris_settings = await this.auth.apiFind(this.routes.TEST_CERT_JURISDICTION_SETTINGS);
        if (!juris_settings.length){
          throw ("ERR_NO_JURIS_SETTINGS");
        }
        const jurisData = {
          ...juris_settings[0],
          allowCandidateWaitlist: juris_settings[0].allowCandidateWaitlist === 1,
        }
        this.myJurisInfo.next(jurisData);
        return jurisData;
      } catch (error) {
        console.log('Error loading jurisdiction info', error);
        this.myJurisInfo.next(null);
        throw error;
      } finally {
        this.jurisInfoLoadingPromise = null;
      }
    })();

    return this.jurisInfoLoadingPromise;
  }
  
  getJurisdictionInfo () {
    if (this.myJurisInfo.getValue() !== null) return this.myJurisInfo;
    try {
      this.loadJurisdictionInfo();
    } catch (error) {
      console.error('Error in getJurisdictionInfo:', error);
      throw error;
    }
    return this.myJurisInfo;
  }

}
